import { AxiosError } from 'axios';
import { apiUrl1, authHTTPClient } from './auth-client';
import { ICustomerBase, ISession, SESSION_HEADERS } from './session';

export interface IBankIDAuthData {
  OrderRef: string;
  AutoStartToken: string;
  QrCode: string;
}

export const enum BankIDOrderStatus {
  Pending = 'Pending',
  Complete = 'Complete',
  Failed = 'Failed'
}

export const enum PendingHintCode {
  OutstandingTransaction = 'outstandingTransaction',
  NoClient = 'noClient',
  Started = 'started',
  UserMrtd = 'userMrtd',
  UserCallConfirm = 'userCallConfirm',
  UserSign = 'userSign'
}

export const enum FailedHintCode {
  ExpiredTransaction = 'expiredTransaction',
  CertificateError = 'certificateErr',
  UserCancel = 'userCancel',
  Cancelled = 'cancelled',
  StartFailed = 'startFailed'
}

export type BankIDAuthState =
  | {
      OrderRef: string;
      Status: BankIDOrderStatus.Pending;
      HintCode: PendingHintCode | string;
      QrCode: string;
    }
  | { OrderRef: string; Status: BankIDOrderStatus.Failed; HintCode: FailedHintCode | string }
  | {
      OrderRef: string;
      Status: BankIDOrderStatus.Complete;
      CompletionData: IBankIDCompletionData;
      Customers: ICustomerBase[];
    };

export interface IBankIDCompletionData {
  User: IBankIdUserInfo;
  Device: IBankIdDeviceInfo;
  BankIdIssueDate: string;
  StepUp: boolean;
  Signature: string;
  OcspResponse: string;
}

export interface IBankIdUserInfo {
  PersonalNumber: string;
  Name: string;
  GivenName: string;
  Surname: string;
}

export interface IBankIdDeviceInfo {
  IpAddress: string;
  Uhi: string;
}

export async function initiateBankIdAuth() {
  // Pass a version in parameters, required for transition period from BankID API v5 to v6
  const params = { bankIdVersion: 6 };

  const { data } = await authHTTPClient.post<IBankIDAuthData>(`${apiUrl1}/auth/bankid`, null, {
    params
  });
  return data;
}

export async function collectBankIdAuthState(orderRef: string) {
  const { data } = await authHTTPClient.get<BankIDAuthState>(`${apiUrl1}/auth/bankid/collect`, {
    params: { orderRef }
  });
  return data;
}

export async function finishBankIdAuth(payload: { orderRef: string; customerId: number }) {
  // Pass a version in parameters, required for transition period from BankID API v5 to v6
  const params = { bankIdVersion: 6 };

  try {
    const { data } = await authHTTPClient.post<ISession>(`${apiUrl1}/auth/bankid/finish`, payload, {
      headers: SESSION_HEADERS,
      params
    });
    return data;
  } catch (error) {
    const e = error as AxiosError;
    if (e?.response?.status === 403) {
      throw e;
    }

    throw new Error(e?.response?.data.Message);
  }
}

export async function cancelBankIdAuth(orderRef: string) {
  await authHTTPClient.delete(`${apiUrl1}/auth/bankid`, { params: { orderRef } });
}
