import cx from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  useEffect
} from 'react';
import { useKeydown } from '@lib/useKeydown';
import styles from './table.module.css';

export interface ITableProps<T> {
  items: T[];
  tabIndex?: number;
  hasHeader?: boolean;
  className?: string;
  rowClassName?: string | ((data: T) => string);
  activeRowClassName?: string;
  currentSelectedRow?: number;
  brandColors?: object;
  cyId?: string;
  isFocused?: boolean;
  isBordered?: boolean;

  calculateKey(item: T): string | number;
  onRowSelected(item: T, event?: any): void;
  onRowFocused(item: T): void;
  renderRow(item: T, isActive?: boolean): JSX.Element;
  isNotActive?(item: T): void;
  renderHeader(): JSX.Element;

  onFooterSelected?(e: any): void;
  onFooterFocused?(item: T): void;
  renderFooter?(): JSX.Element;

  onLostFocus?(): void;
}

function TableInner<T>(props: ITableProps<T>, ref: React.Ref<any>) {
  const [currentSelectedRow, setCurrentSelectedRow] = useState(-1);

  useImperativeHandle(ref, () => ({
    setCurrentSelectedRow: (index: number) => {
      setCurrentSelectedRow(index);
    },
    selectNextRow,
    selectPreviousRow
  }));

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (!props.isFocused) {
      return;
    }
    if (tableRef.current) {
      tableRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (currentSelectedRow !== -1 && currentSelectedRow >= props.items.length) {
      props.onLostFocus?.();
      setCurrentSelectedRow(-1);
    }
  }, [props.items.length]);

  function selectPreviousRow() {
    setCurrentSelectedRow(current => {
      let nextIndex = current - 1;

      if (current <= 0) {
        return current;
      }

      if (nextIndex === props.items.length) {
        // hightlight row before footer
        nextIndex = props.items.length - 1;
      }

      props.onRowFocused(props.items[nextIndex]);

      return nextIndex;
    });
  }

  function selectNextRow() {
    setCurrentSelectedRow(current => {
      const nextIndex = current + 1;

      if (nextIndex >= 0 && nextIndex < props.items.length) {
        props.onRowFocused(props.items[nextIndex]);
        return nextIndex;
      }

      if (nextIndex === props.items.length && props.renderFooter) {
        // highlight footer
        setCurrentSelectedRow(footerIndex);
        props.onFooterFocused?.(props.items[footerIndex]);

        return current;
      }

      return current;
    });
  }

  function handleKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowUp':
        event.stopPropagation();
        event.preventDefault();
        selectPreviousRow();
        break;
      case 'ArrowDown':
        event.stopPropagation();
        event.preventDefault();
        selectNextRow();
        break;
      case 'Enter':
        const selectedRow = props.items[currentSelectedRow];

        if (selectedRow) {
          event.stopPropagation();
          props.onRowSelected(selectedRow);
        }
        break;
      default:
        break;
    }
  }

  useKeydown(
    tableRef,
    useCallback(handleKeydown, [
      currentSelectedRow,
      props.items,
      props.onRowFocused,
      props.onRowSelected,
      props.onFooterFocused
    ])
  );

  const footerIndex = props.items.length + 1;

  return (
    <table
      tabIndex={props.tabIndex}
      ref={tableRef}
      cy-id={props.cyId}
      className={cx([styles.root, props.isBordered && styles.bordered, props.className])}
    >
      {props.hasHeader ? (
        <thead>
          <tr>{props.renderHeader()}</tr>
        </thead>
      ) : null}
      <tbody>
        {props.items.map((item, index) => {
          const rowClassName =
            typeof props.rowClassName === 'string'
              ? props.rowClassName
              : props.rowClassName?.(item);

          return (
            <tr
              key={props.calculateKey(item)}
              onClick={e => {
                setCurrentSelectedRow(index);
                props.onRowSelected(item, e);
              }}
              className={cx([
                {
                  [styles.active]: index === currentSelectedRow,
                  [styles.notActiveRow]: props.isNotActive && props.isNotActive(item),
                  [props.activeRowClassName ?? '']:
                    props.activeRowClassName && index === currentSelectedRow,
                  [rowClassName ?? '']: !!rowClassName
                }
              ])}
            >
              {index === currentSelectedRow}
              {props.renderRow(item, index === currentSelectedRow)}
            </tr>
          );
        })}
      </tbody>
      {props.renderFooter ? (
        <tfoot>
          <tr
            onClick={e => {
              if (props.onFooterSelected) {
                setCurrentSelectedRow(footerIndex);
                props.onFooterSelected(e);
              }
            }}
            className={cx([
              {
                [styles.active]: footerIndex === currentSelectedRow,
                [props.activeRowClassName ?? '']:
                  props.activeRowClassName && footerIndex === currentSelectedRow
              }
            ])}
          >
            {props.renderFooter()}
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
}

export const Table = forwardRef(TableInner);
