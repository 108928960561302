import { Modal } from '@ui/modal';
import { Input } from '@ui/input';
import { Table } from '@ui/table';
import { Button } from '@ui/button';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICustomerBase } from '@api/auth/session';
import textSearch from '@utils/text-search';
import { useLoginContext } from '../../routes/login/login.context';
import styles from './customer-instance-select-modal.css';
import cx from 'classnames';

interface ICustomerInstanceSelectProps {
  isOpen: boolean;
  isBusy: boolean;
  customers: ICustomerBase[];
  onRequestClose(): void;
  onCustomerSelect(customer: ICustomerBase): void | Promise<void>;
}

export default function CustomerInstanceSelect(props: ICustomerInstanceSelectProps) {
  const { t } = useTranslation();
  const { processing } = useLoginContext();
  const [selectedCustomer, changeSelectedCustomer] = useState<ICustomerBase | null>(null);
  const [searchString, changeSearch] = useState('');

  const customersToShow = useMemo(
    () =>
      props.customers.filter(
        customer =>
          !searchString || textSearch(`${customer.Name}${customer.Description}`, searchString)
      ),
    [searchString, props.customers]
  );

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (processing) {
      return;
    }

    if (customersToShow.length === 1) {
      try {
        await props.onCustomerSelect(customersToShow[0]);
      } catch (e) {
        // ignore any errors, used just to wait unit request closing
      }

      changeSearch('');
      return;
    }
  }

  async function onCustomerSelected(customer: ICustomerBase) {
    if (processing) {
      return;
    }

    try {
      await props.onCustomerSelect(customer);
    } catch (e) {
      // ignore any errors, used just to wait unit request closing
    }

    changeSearch('');
  }

  return (
    <Modal
      headless
      size="xl"
      fullscreen="md-down"
      show={props.isOpen}
      hidesOnBackdropClick={false}
      hidesOnEscape={false}
      onHide={() => {
        changeSearch('');
        props.onRequestClose();
      }}
      body={
        <div className={styles.body}>
          <form onSubmit={onSubmit}>
            <Input
              tabIndex={1}
              placeholder={t('common:search')}
              name="customer-instance-search"
              value={searchString}
              stretch
              onChange={event => {
                changeSearch(event.target.value);
                changeSelectedCustomer(null);
              }}
            />
          </form>
          <div className={cx(styles.authTableContainer, 'scrollable')}>
            {customersToShow.length > 0 ? (
              <Table
                cyId="customers-table"
                key={searchString}
                tabIndex={2}
                items={customersToShow}
                calculateKey={(item: ICustomerBase) => item.Id}
                renderHeader={() => (
                  <>
                    <th>{t('common:name')}</th>
                    <th>{t('common:description')}</th>
                  </>
                )}
                renderRow={(item: ICustomerBase) => (
                  <>
                    <td className={styles.cell}>
                      <span>
                        {item.Name}{' '}
                        {selectedCustomer && selectedCustomer.Id === item.Id && processing
                          ? '...'
                          : null}
                      </span>
                    </td>
                    <td className={styles.cell}>
                      <span>{item.Description}</span>
                    </td>
                  </>
                )}
                onRowSelected={(customer: ICustomerBase) => onCustomerSelected(customer)}
                onRowFocused={(customer: ICustomerBase) => changeSelectedCustomer(customer)}
              />
            ) : (
              t('common:nothingToShow')
            )}
          </div>
        </div>
      }
      footer={
        <div className={styles.modalControl}>
          <Button
            tabIndex={3}
            disabled={props.isBusy || !selectedCustomer}
            value={t('common:send')}
            onClick={() => (selectedCustomer ? onCustomerSelected(selectedCustomer) : null)}
          />
          <Button
            tabIndex={4}
            disabled={props.isBusy}
            value={t('common:close')}
            onClick={() => {
              changeSearch('');
              props.onRequestClose();
            }}
          />
        </div>
      }
    />
  );
}
