import { RefObject, useEffect } from 'react';

export function useKeydown(
  ref: RefObject<HTMLTableElement>,
  listener: (event: KeyboardEvent) => void
) {
  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('keydown', listener);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('keydown', listener);
      }
    };
  }, [listener]);
}
