import { useCallback, useEffect, useState } from 'react';
import {
  BankIDOrderStatus,
  cancelBankIdAuth,
  collectBankIdAuthState,
  FailedHintCode,
  finishBankIdAuth,
  initiateBankIdAuth,
  PendingHintCode
} from '@api/auth/bank-id';
import { useSession } from '@lib/useSession';
import { AxiosError } from 'axios';
import { ICustomerBase, IMaintenance, isMaintenance } from '@api/auth/session';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

interface IAuthData {
  orderRef: string;
  autoStartToken: string;
  qrCode?: string;
  orderStatus: BankIDOrderStatus;
  hintCode?: PendingHintCode | FailedHintCode | string;
  autoStart?: boolean;
}

type AuthCompletedFn = (
  customers: ICustomerBase[],
  completeAuth: (customerId: number) => Promise<void>
) => void;

const COLLECT_TIMEOUT = 2000;

export function useBankIdAuth(onAuthCompleted: AuthCompletedFn) {
  const { t } = useTranslation();
  const { updateSession } = useSession();
  const [authData, setAuthData] = useState<IAuthData>();
  const [maintenanceInfo, setMaintenanceInfo] = useState<IMaintenance | null>(null);

  async function initiateAuth(autoStart?: boolean) {
    setMaintenanceInfo(null);
    const data = await initiateBankIdAuth();
    setAuthData({
      orderRef: data.OrderRef,
      autoStartToken: data.AutoStartToken,
      qrCode: data.QrCode,
      autoStart,
      orderStatus: BankIDOrderStatus.Pending,
      hintCode: PendingHintCode.OutstandingTransaction
    });

    if (autoStart) {
      window.location.replace(`bankid:///?autostarttoken=${data.AutoStartToken}&redirect=null`);
    }
  }

  async function cancelAuth() {
    if (!authData) return;
    await cancelBankIdAuth(authData.orderRef);
    setAuthData(undefined);
  }

  async function finishAuth(customerId: number) {
    if (!authData) return;

    try {
      const session = await finishBankIdAuth({ orderRef: authData.orderRef, customerId });

      updateSession(session);
    } catch (e) {
      const error = e as AxiosError;
      if (error.response && isMaintenance(error.response.data)) {
        setMaintenanceInfo(error.response.data);
        setAuthData(undefined);
      } else {
        setAuthData(undefined);
        throw e;
      }
    }
  }

  const getUserMessage = useCallback(() => {
    if (!authData) return '';

    const { hintCode, autoStart, orderStatus } = authData;

    switch (hintCode) {
      case PendingHintCode.OutstandingTransaction:
        return autoStart ? t('login:bankId:RFA13') : t('login:bankId:RFA1');
      case PendingHintCode.NoClient:
        return t('login:bankId:RFA1');
      case PendingHintCode.Started:
        return isMobile ? t('login:bankId:RFA15_A') : t('login:bankId:RFA15_B');
      case PendingHintCode.UserMrtd:
        return t('login:bankId:RFA23');
      case PendingHintCode.UserSign:
        return t('login:bankId:RFA9');

      case FailedHintCode.ExpiredTransaction:
        return t('login:bankId:RFA8');
      case FailedHintCode.CertificateError:
        return t('login:bankId:RFA16');
      case FailedHintCode.UserCancel:
        return t('login:bankId:RFA6');
      case FailedHintCode.Cancelled:
        return t('login:bankId:RFA3');
      case FailedHintCode.StartFailed:
        return autoStart ? t('login:bankId:RFA17_A') : t('login:bankId:RFA17_B');

      default: {
        if (orderStatus === BankIDOrderStatus.Pending) {
          return t('login:bankId:RFA21');
        }

        if (orderStatus === BankIDOrderStatus.Failed) {
          return t('login:bankId:RFA22');
        }
      }
    }

    return '';
  }, [authData?.orderStatus, authData?.hintCode, authData?.autoStart, t]);

  useEffect(() => {
    if (!authData) return;

    const { orderRef, orderStatus } = authData;
    if (orderStatus !== BankIDOrderStatus.Pending) {
      return;
    }

    let timerId = 0;
    const collect = async () => {
      try {
        const state = await collectBankIdAuthState(orderRef);
        setAuthData(current => {
          if (!current) return current;

          if (state.Status === BankIDOrderStatus.Complete) {
            setAuthData({
              ...current,
              orderStatus: state.Status,
              qrCode: undefined,
              hintCode: undefined
            });

            onAuthCompleted(state.Customers, finishAuth);
          } else if (state.Status === BankIDOrderStatus.Pending) {
            setAuthData({
              ...current,
              orderStatus: state.Status,
              qrCode: state.QrCode,
              hintCode: state.HintCode
            });
          } else if (state.Status === BankIDOrderStatus.Failed) {
            setAuthData({
              ...current,
              orderStatus: state.Status,
              qrCode: undefined,
              hintCode: state.HintCode
            });
          }

          return current;
        });

        if (state.Status !== BankIDOrderStatus.Pending) {
          window.clearInterval(timerId);
          timerId = 0;
        }
      } catch {
        // Ignore any error
      }
    };

    timerId = window.setInterval(collect, COLLECT_TIMEOUT);
    return () => {
      if (timerId !== 0) {
        window.clearInterval(timerId);
        timerId = 0;
      }
    };
  }, [authData?.orderRef, authData?.orderStatus]);

  return {
    authData,
    setAuthData,
    initiateAuth,
    cancelAuth,
    finishAuth,
    getUserMessage,
    maintenanceInfo
  };
}
