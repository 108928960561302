import React, { Suspense, useCallback, useRef, useState } from 'react';
import { Button } from '@ui/button';
import { useTranslation } from 'react-i18next';
import { Id as ToastId } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { ICustomerBase } from '@api/auth/session';
import QRCode from 'react-qr-code';
import { BankIDOrderStatus } from '@api/auth/bank-id';
import { showUnexpectedError, showWarning } from '@lib/toast';
import CustomerInstanceSelect from '../../components/customer-instance-select-modal/customer-instance-select-modal';
import { MaintenanceMessage } from '@ui/planned-maintenance/maintenance-message';
import { useLoginContext } from './login.context';
import BankIdLogo from '../../../public/logo-bank-id.svg';
import { isMobile } from 'react-device-detect';
import { Spinner } from '@tiltid-modern/ui-components';
import { useBankIdAuth } from '../../hooks/useBankIdAuth';
import styles from './login.css';

export default function LoginBankID() {
  const { t } = useTranslation();
  const { processing, setProcessing } = useLoginContext();

  const [isModalOpened, showModal] = useState(false);
  const toast = useRef<ToastId>('');

  const onSelectCustomer = useCallback(
    (customers: ICustomerBase[], completeAuth: (customerId: number) => Promise<void>) => {
      if (customers.length === 0) {
        toast.current = showWarning(t('login:bankId:noInstances'));
        setAuthData(undefined);
      } else if (customers.length === 1) {
        runSafe(async () => await completeAuth(customers[0].Id));
      } else {
        setCustomers(customers);
        showModal(true);
      }
    },
    []
  );

  const {
    authData,
    setAuthData,
    initiateAuth,
    cancelAuth,
    finishAuth,
    getUserMessage,
    maintenanceInfo
  } = useBankIdAuth(onSelectCustomer);

  const [customers, setCustomers] = useState<ICustomerBase[]>([]);

  async function runSafe(promise: () => Promise<any>) {
    setProcessing(true);
    try {
      await promise();
    } catch (error) {
      toast.current = showUnexpectedError(error);
    }
    setProcessing(false);
  }

  return (
    <>
      <div className={styles.content}>
        <h4>{t('login:signIn')}</h4>

        <MaintenanceMessage maintenance={maintenanceInfo} />

        {!authData?.orderRef && (
          <Button
            className={styles.bankIdBtn}
            icon={<img className={styles.icon} src={BankIdLogo} />}
            value={t('login:bankId:mobileAuth')}
            onClick={async () => {
              await runSafe(async () => await initiateAuth(isMobile));
            }}
          />
        )}

        {authData?.qrCode && !authData?.autoStart && (
          <div className={styles.qrCodeWrapper}>
            <QRCode value={authData.qrCode} size={190} />
          </div>
        )}

        {authData?.hintCode && (
          <p
            className={styles.bankIdMessage}
            dangerouslySetInnerHTML={{ __html: getUserMessage() }}
          ></p>
        )}

        {authData?.autoStart && authData?.orderStatus === BankIDOrderStatus.Pending && (
          <>
            <p>
              <Spinner />
            </p>
            <Button
              className={styles.bankIdBtn}
              value={t('login:bankId:authOnOtherDevice')}
              onClick={async () => {
                setAuthData(current => {
                  if (!current) return current;
                  return { ...current, autoStart: false };
                });
              }}
            />
          </>
        )}

        {authData?.orderStatus === BankIDOrderStatus.Pending && (
          <Button
            className={styles.bankIdBtn}
            icon={<FontAwesomeIcon icon={faXmark} />}
            value={t('login:bankId:cancel')}
            onClick={async () => {
              await runSafe(cancelAuth);
            }}
          />
        )}

        {authData?.orderStatus === BankIDOrderStatus.Failed && (
          <Button
            className={styles.bankIdBtn}
            icon={<FontAwesomeIcon icon={faRepeat} />}
            value={t('login:bankId:tryAgain')}
            onClick={async () => {
              await runSafe(async () => initiateAuth(isMobile));
            }}
          />
        )}
      </div>

      <Suspense fallback={<Spinner />}>
        <CustomerInstanceSelect
          onRequestClose={() => {
            showModal(false);
            setAuthData(undefined);
          }}
          isBusy={processing}
          isOpen={isModalOpened}
          customers={customers}
          onCustomerSelect={async customer => {
            showModal(false);
            await runSafe(async () => finishAuth(customer.Id));
          }}
        />
      </Suspense>
    </>
  );
}
