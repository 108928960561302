// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-module__root--vB8c11kH7_sif1I4XL1h{border-collapse:collapse;border-spacing:0;width:100%;margin-bottom:20px}.table-module__root--vB8c11kH7_sif1I4XL1h tbody,.table-module__root--vB8c11kH7_sif1I4XL1h thead{background-color:rgb(var(--theme-listctrl_bkgnd_norm));color:rgb(var(--theme-listctrl_txt_norm))}.table-module__active--gpr7WYt4FbKXChlZ2mC4{background-color:rgb(var(--app-active-row_txt));color:rgb(var(--app-tablerow_active_txt))}.table-module__root--vB8c11kH7_sif1I4XL1h td,.table-module__root--vB8c11kH7_sif1I4XL1h th{padding:8px;text-align:left}.table-module__root--vB8c11kH7_sif1I4XL1h tr{cursor:pointer}.table-module__bordered--EsDqQ65TtyOxbpc3CCAz>tbody>tr>td{border-top:1px solid rgb(var(--theme-listctrl_line_norm))}.table-module__notActiveRow--nnS5IgBzP9pG39PWsR9X{color:rgb(var(--theme-listctrl_line_disable))}", "",{"version":3,"sources":["webpack://./packages/ui-components/src/table/table.module.css"],"names":[],"mappings":"AAAA,0CACE,wBAAyB,CACzB,gBAAiB,CACjB,UAAW,CACX,kBACF,CAEA,gGAEE,sDAAuD,CACvD,yCACF,CAEA,4CACE,+CAAgD,CAChD,yCACF,CAEA,0FAEE,WAAY,CACZ,eACF,CAEA,6CACE,cACF,CAEA,0DACE,yDACF,CAEA,kDACE,6CACF","sourcesContent":[".root {\r\n  border-collapse: collapse;\r\n  border-spacing: 0;\r\n  width: 100%;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.root thead,\r\n.root tbody {\r\n  background-color: rgb(var(--theme-listctrl_bkgnd_norm));\r\n  color: rgb(var(--theme-listctrl_txt_norm));\r\n}\r\n\r\n.active {\r\n  background-color: rgb(var(--app-active-row_txt));\r\n  color: rgb(var(--app-tablerow_active_txt));\r\n}\r\n\r\n.root th,\r\n.root td {\r\n  padding: 8px;\r\n  text-align: left;\r\n}\r\n\r\n.root tr {\r\n  cursor: pointer;\r\n}\r\n\r\n.bordered > tbody > tr > td {\r\n  border-top: 1px solid rgb(var(--theme-listctrl_line_norm));\r\n}\r\n\r\n.notActiveRow {\r\n  color: rgb(var(--theme-listctrl_line_disable));\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "table-module__root--vB8c11kH7_sif1I4XL1h",
	"active": "table-module__active--gpr7WYt4FbKXChlZ2mC4",
	"bordered": "table-module__bordered--EsDqQ65TtyOxbpc3CCAz",
	"notActiveRow": "table-module__notActiveRow--nnS5IgBzP9pG39PWsR9X"
};
export default ___CSS_LOADER_EXPORT___;
